<template>
  <div class="box-dialog">
    <el-dialog title="选择商品类目" :visible.sync="selfShow" :close-on-click-modal="false">
      <div class="box-category">
        <div>{{ activeCategoryStr }}</div>
        <b-goods-category
          class="m-t-5"
          :ids="activeCategoryIds"
          :isInit="!activeCategoryIds.length"
          @success="getActiveCategoryList"
        />
      </div>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: []
    }
  },
  computed: {
    // 类目字符串
    activeCategoryStr() {
      let result = ''
      let listName = this.selfData.category.map((item) => item.name)
      result = listName.join(' / ')
      return result // 类目名称拼接成字符串
    },
    // 选中类目id
    activeCategoryIds() {
      return this.selfData.category.map((item) => item.id)
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 获取选中类目列表
    getActiveCategoryList(list) {
      this.selfData.category = []
      list.forEach((itemSt) => {
        itemSt.category.forEach((itemNd) => {
          if (itemSt.title.active_id === itemNd.id) {
            this.selfData.category.push(this.$util.deepCopy(itemNd))
          }
        })
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      if (this.selfData.category.length) {
        let lastIdx = this.selfData.category.length - 1
        let lastObj = this.selfData.category[lastIdx]
        if (lastObj.has_children) {
          this.$message.error('请选择到末级类目')
          return
        }
      } else {
        this.$message.error('请选择类目')
        return
      }
      this.$emit('update:data', this.$util.deepCopy(this.selfData))
      this.$nextTick(() => {
        this.popupCancel()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
  .box-category {
    font-size: 12px;
  }
}
</style>
