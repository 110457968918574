<template>
  <div class="page-main">
    <div class="page-l" v-if="billId">
      <b-detail-nav :id="billId" />
    </div>
    <div class="page-r">
      <!-- <b-detail-step v-if="billId" :status="detailData.formData.status" :list="stepListData" /> -->
      <div class="m-b-10">
        <el-button v-if="!isDisabledPage" type="primary" size="mini" @click="itemSave">
          保存
        </el-button>
        <el-button
          v-if="billId && detailData.formData.status === 0"
          type="success"
          size="mini"
          @click="itemPush"
        >
          提交审核
        </el-button>
        <el-button
          v-if="
            billId &&
            detailData.formData.status === 1 &&
            $tool.hasControl(['/gcvip/checkBill/leaderReject'])
          "
          type="danger"
          size="mini"
          @click="itemReject"
        >
          审核拒绝
        </el-button>
        <el-button
          v-if="
            billId &&
            detailData.formData.status === 1 &&
            $tool.hasControl(['/gcvip/checkBill/leaderDoAudit'])
          "
          type="success"
          size="mini"
          @click="itemPass"
        >
          审核通过
        </el-button>
        <el-button
          v-if="billId"
          type="primary"
          size="mini"
          :disabled="$util.isBlankVue(billId)"
          @click="itemPrint"
        >
          打印
        </el-button>
        <el-button v-if="billId" type="success" size="mini" @click="itemCopy">引用新增</el-button>
        <el-button v-if="billId" type="default" size="mini" @click="jumpAdd">新增</el-button>
      </div>
      <e-form
        class="p-t-10 b-t-1"
        ref="refForm1"
        :config="formConfig"
        :column="formColumn1"
        :data.sync="detailData.formData"
        :disabled="isDisabledPage"
      />
      <div class="table-box" :style="{ height: `${this.$util.getViewHeight() - 278}px` }">
        <TableSale
          tableTitle="bom表清单"
          :parent="null"
          :data.sync="detailData.tableSaleData"
          :disabled="isDisabledPage"
          :statusName="detailData.formData.status_name"
          :unitList="unitList"
        />
      </div>
      <e-form
        class="m-t-10"
        :config="formConfig"
        :column="formColumn2"
        :data.sync="detailData.formData"
        :disabled="isDisabledPage"
      />
      <div class="total-box">
        <span v-for="(item, index) in formColumn3" :key="index" class="total-item">
          <span>{{ item.title }}:</span>
          <span class="total-item-price">{{ detailData.formData[item.field] }}</span>
          <span>{{ item.suffix }}</span>
        </span>
      </div>
      <b-popup-customer
        v-if="isShowPopupCustomer"
        :show.sync="isShowPopupCustomer"
        :id="activeId"
        :salerName="detailData.formData.saler_name"
        @success="getCustomerList"
      />
    </div>
    <!-- <p class="m-t-10">{{ detailData }}</p> -->
  </div>
</template>

<script>
import math from './math'
import TableSale from './table-sale'
export default {
  components: {
    TableSale
  },
  data() {
    return {
      isUpdatePage: false, // 是否更新过页面数据
      isPassRequired: true, // 是否通过必填
      isItemPush: false,
      isItemPrint: false,
      stepListData: [
        {
          key: '提交核算单',
          val: 0,
          des: [
            {
              key: '提交人',
              val: ''
            },
            {
              key: '提交时间',
              val: ''
            }
          ]
        },
        {
          key: '销售总监审核',
          val: 1,
          des: [
            {
              key: '审核人',
              val: ''
            },
            {
              key: '审核时间',
              val: ''
            }
          ]
        },
        {
          key: '完成',
          val: 2,
          des: [
            {
              key: '完成时间',
              val: ''
            }
          ]
        }
      ],
      formConfig: {
        width: '200px'
      },
      formColumn1: [
        {
          type: 'date',
          title: '销售日期',
          field: 'bill_date',
          required: true,
          disabled: false,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '销售单号',
          field: 'bill_code',
          placeholder: '自动生成',
          required: true,
          disabled: true
        },
        {
          type: 'select',
          title: '业务员',
          field: 'saler_name',
          options: []
        },
        {
          type: 'select',
          title: '客户',
          field: 'company_name',
          options: [],
          optionDo: { title: '编辑', fn: () => {} },
          emptyDo: { title: '新增客户', fn: () => {} },
          remote: true,
          remoteMethod: (val) => {
            this.getCustomerList(val)
          }
        },
        {
          type: 'select',
          title: '开单公司',
          field: 'saler_company_name',
          required: true,
          width: '290px',
          options: this.$constant.companyList
        },
        {
          type: 'text',
          title: '单据备注',
          field: 'bill_remark'
        },
        {
          type: 'text',
          title: '修改人',
          field: 'update_name',
          placeholder: '自动生成',
          disabled: true
        }
      ],
      formColumn2: [
        {
          type: 'number',
          title: '销售运费',
          field: 'freight_fees_bill',
          suffix: '元',
          fnChange: () => {
            math.totalFormData(this.detailData) // 计算-表格汇总计算
          }
        },
        {
          type: 'number',
          title: '打包费',
          field: 'pack_fees_bill',
          suffix: '元',
          fnChange: () => {
            math.totalFormData(this.detailData) // 计算-表格汇总计算
          }
        },
        {
          type: 'number',
          title: '其他费用',
          field: 'other_fees_bill',
          suffix: '元',
          fnChange: () => {
            math.totalFormData(this.detailData) // 计算-表格汇总计算
          }
        },
        {
          type: 'number',
          title: '税率',
          field: 'bill_tax_rate',
          suffix: '%',
          fnChange: () => {
            math.totalFormData(this.detailData) // 计算-表格汇总计算
          }
        },
        {
          type: 'number',
          title: '税金',
          field: 'tax_fees_total',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        },
        {
          type: 'number',
          title: '费用合计',
          field: 'fees_total',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        }
      ],
      formColumn3: [
        {
          type: 'number',
          title: '订单成本',
          field: 'cost_price_total',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        },
        {
          type: 'number',
          title: '订单销售金额',
          field: 'sales_amount_total',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        },
        {
          type: 'number',
          title: '订单利润',
          field: 'profit_total',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        },
        {
          type: 'number',
          title: '订单利润率',
          field: 'profit_rate_total',
          suffix: '%',
          placeholder: '自动计算',
          disabled: true
        },
        {
          type: 'number',
          title: '提成金额',
          field: 'push_money',
          suffix: '元',
          placeholder: '自动计算',
          disabled: true
        }
      ],

      detailData: {
        formData: {
          bill_date: this.$XEUtils.toDateString(this.$XEUtils.now(), 'yyyy-MM-dd'), // 销售日期
          bill_code: '', // 销售单号
          saler_name: '', // 业务员
          commission_rate: '', // 提成比例
          company_name: '', // 客户
          company_uuid: '', // 客户uuid
          saler_company_name: '', // 开单公司
          bill_remark: '', // 单据备注
          update_name: '', // 修改人
          freight_fees_bill: '', // 销售运费
          pack_fees_bill: '', // 打包费
          other_fees_bill: '', // 其他费用
          bill_tax_rate: '', // 税率
          tax_fees_total: '', // 税金
          fees_total: '', // 费用合计
          cost_price_total: '', // 订单成本
          sales_amount_total: '', // 订单销售金额
          profit_total: '', // 订单利润
          profit_rate_total: '', // 订单利润率
          push_money: '' // 提成金额
        },
        tableSaleData: []
      }, // 详情数据
      detailTimer: null, // 详情数据更新计时器

      saleList: [],
      customerList: [],
      unitList: [],

      isShowPopupCustomer: false,
      activeId: null
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    },
    isDisabledPage() {
      return this.billId && !!this.detailData.formData.status
    },
    getRoleObj() {
      return this.$store.getters.getRoleObj
    }
  },
  watch: {
    detailData: {
      handler(val) {
        this.isUpdatePage = true
        this.isPassRequired = true
        this.initCompanyUuid()
        this.setPassRequired(val.tableSaleData)
      },
      deep: true
    },
    'detailData.tableSaleData': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            if (this.detailTimer) {
              clearTimeout(this.detailTimer)
            }
            this.detailTimer = setTimeout(() => {
              math.totalDetailData(this.detailData) // 计算-汇总详情
              this.detailTimer = null
            }, 100)
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    if (this.billId) {
      this.getDetailData()
    } else {
      this.detailData.formData.saler_name = this.$store.getters.getLoginAccount.nick_name // 初始化-业务员
      this.detailData.formData.commission_rate = this.$store.getters.getLoginAccount.commission_rate // 初始化-提成比例
      this.detailData.tableSaleData = [this.$util.deepCopy(this.$constant.tableSaleObj)] // 初始化-数据结构
    }

    // 初始化-新增客户
    this.formColumn1.forEach((item) => {
      if (item.field === 'company_name') {
        item.optionDo.fn = this.customerEdit
        item.emptyDo.fn = this.customerAdd
      }
    })

    this.getSaleList()
    this.getCustomerList()
    this.getUnitList()
  },
  methods: {
    // 初始化-步骤条
    initStepListData() {
      this.stepListData[0].des[0].val = this.detailData.formData.submiter // 提交人
      this.stepListData[0].des[1].val = this.detailData.formData.submit_time // 提交时间
      this.stepListData[1].des[0].val = this.detailData.formData.leader // 审核人
      this.stepListData[1].des[1].val = this.detailData.formData.leader_time // 审核时间
      this.stepListData[2].des[0].val = this.detailData.formData.finish_time // 完成时间
    },
    // 初始化-客户uuid
    initCompanyUuid() {
      this.customerList.forEach((item) => {
        if (this.detailData.formData.company_name === item.key) {
          this.detailData.formData.company_uuid = item.uuid
        }
      })
    },
    // 设置通过必填
    setPassRequired(data) {
      data.forEach((item) => {
        if (!(item.category && item.craft)) this.isPassRequired = false
        if (item.tableBomData.length) this.setPassRequired(item.tableBomData)
      })
    },
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/detail',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.detailData = this.$util.deepCopy(res.data)
          this.initStepListData()
          setTimeout(() => {
            this.isUpdatePage = false
          }, 100)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取业务员列表
    getSaleList() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          filterData: {
            role_name: '业务员'
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.nick_name,
              val: item.nick_name
            }
          })
          this.saleList = result
          this.formColumn1.forEach((item) => {
            if (item.field === 'saler_name') item.options = this.$util.deepCopy(this.saleList)
          })
        } else {
          return
        }
      })
    },
    // 获取客户列表
    getCustomerList(keyword = '') {
      this.$api({
        method: 'post',
        url: '/admin/customers/search',
        data: {
          keyword
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.customer_name,
              val: item.customer_name,
              id: item.id,
              uuid: item.company_uuid
            }
          })
          this.customerList = result
          this.formColumn1.forEach((item) => {
            if (item.field === 'company_name') item.options = this.$util.deepCopy(this.customerList)
          })

          // 用于客户编辑保存成功
          this.customerList.forEach((item) => {
            if (item.uuid === this.detailData.formData.company_uuid) {
              this.detailData.formData.company_name = item.key
            }
          })
        }
      })
    },
    // 获取单位列表
    getUnitList() {
      this.$api({
        method: 'post',
        url: '/admin/unitSetting/search',
        params: {
          keyword: ''
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.name,
              val: item.name
            }
          })
          this.unitList = result
        }
      })
    },
    // 新增客户
    customerAdd() {
      let refForm1 = this.$refs.refForm1
      refForm1.$refs.company_name[0].blur()
      this.activeId = null
      this.isShowPopupCustomer = true
    },
    customerEdit(data) {
      this.activeId = data.id
      this.isShowPopupCustomer = true
    },
    // 项-保存
    itemSave() {
      if (!this.detailData.formData.saler_company_name) {
        this.$message.error('开单公司为必填项')
        return
      }
      if (!this.isPassRequired) {
        this.$message.error('商品类目和品名为必填项')
        return
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/checkBill/saveOrUpdate',
        data: this.detailData
      }).then((res) => {
        if (res.data.state === 'ok') {
          if (this.billId) {
            if (this.isItemPush) {
              this.isItemPush = false
              this.itemPushConfirm()
              return
            }
            if (this.isItemPrint) {
              this.isItemPrint = false
              this.itemPrintConfirm()
              return
            }
            this.$message.success('保存成功')
            this.getDetailData()
          } else {
            this.$message.success('保存成功')
            this.$router.push({
              path: '/sale/order/detail',
              query: { id: res.data.id }
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-提交审核
    itemPush() {
      if (this.isUpdatePage) {
        this.isItemPush = true
        this.itemSave()
      } else {
        this.itemPushConfirm()
      }
    },
    // 项-提交审核-确认
    itemPushConfirm() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/salerDoAudit',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已提交')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核拒绝
    itemReject() {
      this.$confirm(
        `销售单号：${this.detailData.formData.bill_code}，该核算单是否驳回审核?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
        .then(() => {
          this.itemRejectConfirm()
        })
        .catch(() => {})
    },
    // 项-审核拒绝-确认
    itemRejectConfirm() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderReject',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已拒绝')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核通过
    itemPass() {
      this.$confirm(
        `销售单号：${this.detailData.formData.bill_code}，该核算单是否通过审核?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
        .then(() => {
          this.itemPassConfirm()
        })
        .catch(() => {})
    },
    // 项-审核通过-确认
    itemPassConfirm() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderDoAudit',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已通过')
          this.getDetailData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-打印
    itemPrint() {
      if (this.isUpdatePage) {
        this.isItemPrint = true
        this.itemSave()
      } else {
        this.itemPrintConfirm()
      }
    },
    // 项-打印-确认
    itemPrintConfirm() {
      window.open(`/sale/order/print?id=${this.billId}`, '_blank')
    },
    // 项-引用新增
    itemCopy() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/copy',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('引用新增成功')
          setTimeout(() => {
            this.$router.push(`/sale/order/detail?id=${res.data.id}`)
          }, 1500)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 跳转-新增
    jumpAdd() {
      this.$router.push('/sale/order/detail')
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  position: relative;
  display: flex;
  height: 100%;
  .page-l {
    margin: 0 10px 10px 0;
  }
  .page-r {
    flex: 1;
  }
  .table-box {
    border: 1px solid #e8eaec;
    overflow-y: auto;
    overflow-x: hidden;
    .scroll-bar();
  }
  .total-box {
    padding: 15px 10px 5px 10px;
    font-size: 12px;
    border-top: 1px solid #e8eaec;
    color: #606266;
    .total-item {
      margin-right: 20px;
      .total-item-price {
        padding: 0 5px;
        font-weight: bold;
        font-size: 14px;
        color: @colorRed;
      }
    }
  }
}
</style>
